
import './site.css';
import { useState, useEffect, useRef } from 'react';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2, FiSettings } from 'react-icons/fi';
import { FaFacebookSquare, FaInstagram, FaWhatsappSquare, FaLaptop, FaApple, FaAndroid, FaMailBulk } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Zoom } from 'react-slideshow-image';
import YouTube from 'react-youtube';
import { slide as Menu } from 'react-burger-menu'

import firebase from '../../services/firebaseConnection';

import Modalcidade from '../../components/Modalcidade';
import Modalimprensa from '../../components/Modalimprensa';
import Modaltrabalho from '../../components/Modaltrabalho';
import Modalprojeto from '../../components/Modalprojeto';

import logotonatrilha from '../imagens/logotonatrilha.png';
import logotrilha from '../imagens/logotrilhasup.png';
import logotrilharetangular from '../imagens/logotrilhasupretangular.png';
import avatar from '../imagens/logotrilhasup.png';
import logominasbranco from '../imagens/minasbranco.png';
import logominasvermelho from '../imagens/minasvermelho.png';

import google from '../imagens/bt_google.png';
import apple from '../imagens/bt_apple.png';


import imgtiradentes from '../imagens/imgtiradentes.png';


//import 'react-slideshow-image/dist/styles.css';

//@import "react-slideshow-image/dist/styles.css";
function showSettings (event){
  event.preventDefault();

}

const zoomInProperties = {
  indicators: true,
  scale: 1.4,
  backgroundColor: '#AACE45'
}

const opts = {
  height: '320',
  width: '460',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};

const listRef = firebase.firestore().collection('cidades').orderBy('nomecidade', 'asc');
const listRef2 = firebase.firestore().collection('trabalhos').orderBy('nometrabalho', 'asc');
const listRef3 = firebase.firestore().collection('projetos').orderBy('created', 'asc');
const listRef4 = firebase.firestore().collection('parceiros').orderBy('created', 'asc');
const listRef5 = firebase.firestore().collection('imprensa').orderBy('created', 'desc');



export default function Dashboard(){


  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();
 
  const [loading2, setLoading2] = useState(true);
  const [loadingMore2, setLoadingMore2] = useState(false);
  const [isEmpty2, setIsEmpty2] = useState(false);
  const [lastDocs2, setLastDocs2] = useState();

  const [loading3, setLoading3] = useState(true);
  const [loadingMore3, setLoadingMore3] = useState(false);
  const [isEmpty3, setIsEmpty3] = useState(false);
  const [lastDocs3, setLastDocs3] = useState();

  const [loading4, setLoading4] = useState(true);
  const [loadingMore4, setLoadingMore4] = useState(false);
  const [isEmpty4, setIsEmpty4] = useState(false);
  const [lastDocs4, setLastDocs4] = useState();
  const [loading5, setLoading5] = useState(true);
  const [loadingMore5, setLoadingMore5] = useState(false);
  const [isEmpty5, setIsEmpty5] = useState(false);
  const [lastDocs5, setLastDocs5] = useState();

  const [complemento, setComplemento] = useState();
  const [texto1, setTexto1] = useState();
  const [texto2, setTexto2] = useState();
  const [texto3, setTexto3] = useState();
  const [texto4, setTexto4] = useState();
  const [texto5, setTexto5] = useState();
  const [texto6, setTexto6] = useState();
  const [linkvideo, setLinkvideo] = useState();

  const [banner1, setBanner1] = useState();
  const [banner2, setBanner2] = useState();
  const [banner3, setBanner3] = useState();
  const [banner4, setBanner4] = useState();


  const images = [
    {imgbanner: banner1},
    {imgbanner: banner2},
    {imgbanner: banner3},
    {imgbanner: banner4},
  ];
  
  const [nomeconfig, setNomeconfig] = useState('');
  const [sloganconfig, setSloganconfig]= useState('');
  const [desccurtaconfig, setDesccurtaconfig] = useState('');
  const [palavrachaveconfig, setPalavrachaveconfig] = useState('');
  const [razaoconfig, setRazaoconfig] = useState('');
  const [cnpjconfig, setCnpjconfig] = useState('');
  const [cepconfig, setCepconfig] = useState('');
  const [logradouroconfig, setLogradouroconfig] = useState('');
  const [numeroconfig, setNumeroconfig] = useState('');
  const [complementoconfig, setComplementoconfig] = useState('');
  const [bairroconfig, setBairroconfig] = useState('');
  const [cidadeconfig, setCidadeconfig] = useState('');
  const [estadoconfig, setEstadoconfig] = useState('');
  const [paisconfig, setPaisconfig] = useState('');
  const [siteconfig, setSiteconfig] = useState('');
  const [emailconfig, setEmailconfig] = useState('');
  const [linkinstagramconfig, setLinkinstagramconfig] = useState('');
  const [linkfbconfig, setLinkfbconfig] = useState('');
  const [linkyoutubeconfig, setLinkyoutubeconfig] = useState('');
  const [linkdriveconfig, setLinkdriveconfig] = useState('');
  const [linklinkedconfig, setLinklinkedconfig] = useState('');
  const [linkapplestoreconfig, setLinkapplestoreconfig] = useState('');
  const [linkgoogleplayconfig, setLinkgoogleplayconfig] = useState('');
  const [telefone1config, setTelefone1config] = useState('');
  const [telefone2config, setTelefone2config] = useState('');
  const [whats1config, setWhats1config] = useState('');
  const [whats2config, setWhats2config] = useState('');

  const [cidades, setCidades] = useState([]);
  const [trabalhos, setTrabalhos] = useState([]);
  const [projetos, setProjetos] = useState([]);
  const [parceiros, setParceiros] = useState([]);
  const [imprensa, setImprensa] = useState([]);
  
  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();

  const [showPostModal2, setShowPostModal2] = useState(false);
  const [detail2, setDetail2] = useState();

  const [showPostModal3, setShowPostModal3] = useState(false);
  const [detail3, setDetail3] = useState();

  const [showPostModal4, setShowPostModal4] = useState(false);
  const [detail4, setDetail4] = useState();

  useEffect(()=> {

    async function loadTextos(){
      await firebase.firestore().collection('textos')
      .doc('Dj1URS8XolLShHWwG6cP')
      .get()
      .then((snapshot) => {
        setComplemento(snapshot.data().complemento);  
        setTexto1(snapshot.data().texto1);
        setTexto2(snapshot.data().texto2);
        setTexto3(snapshot.data().texto3);
        setTexto4(snapshot.data().texto4);
        setTexto5(snapshot.data().texto5);
        setTexto6(snapshot.data().texto6);
        setLinkvideo(snapshot.data().linkvideo)
      })

    }
    loadTextos();

    async function loadBanners(){
      await firebase.firestore().collection('imagens')
      .doc('AEw61r3kE56Y9rzr0Rn7')
      .get()
      .then((snapshot) => {
        setBanner1(snapshot.data().bannersup1);
        setBanner2(snapshot.data().bannersup2);
        setBanner3(snapshot.data().bannersup3);
        setBanner4(snapshot.data().bannersup4);
      })

    }
    loadBanners();

    async function loadConfig(){
      await firebase.firestore().collection('config')
      .doc('3KG4oi52D34HlaTEiJOL')
      .get()
      .then((snapshot) => {
        setNomeconfig(snapshot.data().nomeconfig);
        setSloganconfig(snapshot.data().sloganconfig);
        setDesccurtaconfig(snapshot.data().desccurtaconfig);
        setPalavrachaveconfig(snapshot.data().palavrachaveconfig);
        setRazaoconfig(snapshot.data().razaoconfig);
        setCnpjconfig(snapshot.data().cnpjconfig);
        setCepconfig(snapshot.data().cepconfig);
        setLogradouroconfig(snapshot.data().logradouroconfig);
        setNumeroconfig(snapshot.data().numeroconfig);
        setComplementoconfig(snapshot.data().complementoconfig);
        setBairroconfig(snapshot.data().bairroconfig);
        setCidadeconfig(snapshot.data().cidadeconfig);
        setEstadoconfig(snapshot.data().estadoconfig);
        setPaisconfig(snapshot.data().paisconfig);
        setSiteconfig(snapshot.data().siteconfig);
        setEmailconfig(snapshot.data().emailconfig);
        setLinkinstagramconfig(snapshot.data().linkinstagramconfig);
        setLinkfbconfig(snapshot.data().linkfbconfig);
        setLinkyoutubeconfig(snapshot.data().linkyoutubeconfig);
        setLinkdriveconfig(snapshot.data().linkdriveconfig);
        setLinklinkedconfig(snapshot.data().linklinkedconfig);
        setLinkapplestoreconfig(snapshot.data().linkapplestoreconfig);
        setLinkgoogleplayconfig(snapshot.data().linkgoogleplayconfig);
        setTelefone1config(snapshot.data().telefone1config);
        setTelefone2config(snapshot.data().telefone2config);
        setWhats1config(snapshot.data().whats1config);
        setWhats2config(snapshot.data().whats2config)
      })
    }
    loadConfig();

    async function loadCidades(){
      await listRef
      .get()
      .then((snapshot) => {
        updateState(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore(false);
      })
  
      setLoading(false);
    }
    loadCidades();

    async function loadTrabalhos(){
      await listRef2.limit(10)
      .get()
      .then((snapshot) => {
        updateStatetrabalhos(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore2(false);
      })
  
      setLoading2(false);
    }
    loadTrabalhos();

    async function loadProjetos(){
      await listRef3.limit(5)
      .get()
      .then((snapshot) => {
        updateStateprojeto(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore3(false);
      })
  
      setLoading3(false);
  
    }

    loadProjetos();

    async function loadParceiros(){
      await listRef4.limit(5)
      .get()
      .then((snapshot) => {
        updateStateparceiros(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore4(false);
      })
  
      setLoading4(false);
    }
    loadParceiros();

    async function loadImprensa(){
      await listRef5
      .get()
      .then((snapshot) => {
        updateStateimprensa(snapshot)
      })
      .catch((err)=>{
        console.log('Deu algum erro: ', err);
        setLoadingMore5(false);
      })
  
      setLoading5(false);
    }
    loadImprensa();

    return () => {

    }
  }, []);

  

  async function updateState(snapshot){
    const isCollectionEmpty = snapshot.size === 0;

    if(!isCollectionEmpty){
      let lista = [];

      snapshot.forEach((doc)=>{
        lista.push({
          id: doc.id,
          nomecidade: doc.data().nomecidade,
          estadocidade: doc.data().estadocidade,
          textosimplescidade: doc.data().textosimplescidade,
          textocompletocidade: doc.data().textocompletocidade,
          avatarUrlcidade: doc.data().avatarUrlcidade,
          linkfbcidade: doc.data().linkfbcidade,
          linkinstagramcidade: doc.data().linkinstagramcidade,
          linksitecidade: doc.data().linksitecidade,
          telefonecidade: doc.data().telefonecidade,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      
      setCidades(cidades => [...cidades, ...lista]);
      setLastDocs(lastDoc);

    }else{
      setIsEmpty(true);
    }

    setLoadingMore(false);

  }

  async function updateStatetrabalhos(snapshot){
    const isCollectionEmpty2 = snapshot.size === 0;

    if(!isCollectionEmpty2){
      let lista2 = [];

      snapshot.forEach((doc)=>{
        lista2.push({
          id2: doc.id,
          nometrabalho: doc.data().nometrabalho,
          textosimplestrabalho: doc.data().textosimplestrabalho,
          textocompletotrabalho: doc.data().textocompletotrabalho,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc2 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      
      setTrabalhos(trabalhos => [...trabalhos, ...lista2]);
      setLastDocs2(lastDoc2);

    }else{
      setIsEmpty2(true);
    }

    setLoadingMore2(false);

  }


  async function updateStateprojeto(snapshot){
    const isCollectionEmpty3 = snapshot.size === 0;

    if(!isCollectionEmpty3){
      let lista3 = [];

      snapshot.forEach((doc)=>{
        lista3.push({
          id3: doc.id,
          avatarUrlprojeto: doc.data().avatarUrlprojeto,
          nomeprojeto: doc.data().nomeprojeto,
          estadoprojeto: doc.data().estadoprojeto,
          textosimplesprojeto: doc.data().textosimplesprojeto,
          textocompletoprojeto: doc.data().textocompletoprojeto,
          linkfbprojeto: doc.data().linkfbprojeto,
          linkinstagramprojeto: doc.data().linkinstagramprojeto,
          linksiteprojeto: doc.data().linksiteprojeto,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc3 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      setProjetos(projetos => [...projetos, ...lista3]);
      setLastDocs3(lastDoc3);
    }else{
      setIsEmpty3(true);
    }
    setLoadingMore3(false);
  }

  async function updateStateparceiros(snapshot){
    const isCollectionEmpty4 = snapshot.size === 0;

    if(!isCollectionEmpty4){
      let lista4 = [];

      snapshot.forEach((doc)=>{
        lista4.push({
          id4: doc.id,
          avatarUrlparceiro: doc.data().avatarUrlparceiro,
          nomeparceiro: doc.data().nomeparceiro,
          linksiteparceiro: doc.data().linksiteparceiro,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc4 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      setParceiros(parceiros => [...parceiros, ...lista4]);
      setLastDocs4(lastDoc4);
    }else{
      setIsEmpty4(true);
    }
    setLoadingMore4(false);
  }

  async function updateStateimprensa(snapshot){
    const isCollectionEmpty5 = snapshot.size === 0;

    if(!isCollectionEmpty5){
      let lista5 = [];

      snapshot.forEach((doc)=>{
        lista5.push({
          id5: doc.id,
          avatarUrlimprensa: doc.data().avatarUrlimprensa,
          assuntoimprensa: doc.data().assuntoimprensa,
          tituloimprensa: doc.data().tituloimprensa,
          texto1imprensa: doc.data().texto1imprensa,
          created: doc.data().created,
          createdFormated: format(doc.data().created.toDate(), 'dd/MM/yyyy')
        })
      })

      const lastDoc5 = snapshot.docs[snapshot.docs.length -1]; //Pegando o ultimo documento buscado
      setImprensa(imprensa => [...imprensa, ...lista5]);
      setLastDocs5(lastDoc5);
    }else{
      setIsEmpty5(true);
    }
    setLoadingMore5(false);
  }


  function togglePostModal(item){
    setShowPostModal(!showPostModal) //trocando de true pra false
    setDetail(item);
  }

  function togglePostModalImprensa(item2){
    setShowPostModal2(!showPostModal2) //trocando de true pra false
    setDetail2(item2);
  }

  function togglePostModalTrabalho(item3){
    setShowPostModal3(!showPostModal3) //trocando de true pra false
    setDetail2(item3);
  }

  function togglePostModalProjeto(item4){
    setShowPostModal4(!showPostModal4) //trocando de true pra false
    setDetail2(item4);
  }  
  
  function onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

    return(
      <div style={{backgroundColor: '#fff'}}>

      <Zoom {...zoomInProperties}>
        {images.map((each, index) => (
          <div key={index} style={{width: "100%", height: "100%"}}>
            <img style={{ objectFit: "cover", width: "100%", height: "500px"}} src={each.imgbanner} />
          </div>
        ))}
      </Zoom>

      <div className="Menu">
      &nbsp;&nbsp;&nbsp;
        <a href="#Início">Início</a>&nbsp;&nbsp;&nbsp;
        <a href="#Trabalhos">Trabalhos Desenvolvidos</a>&nbsp;&nbsp;&nbsp;
        <a href="#Projetos">Projetos</a>&nbsp;&nbsp;&nbsp;
        <a href="#Noticias">Notícias</a>&nbsp;&nbsp;&nbsp;
        <a href="#Downloads">Downloads</a>&nbsp;&nbsp;&nbsp;
        <a href="#Cidades">Cidades</a>&nbsp;&nbsp;&nbsp;
        <a href="#Receptivos">Receptivos</a>&nbsp;&nbsp;&nbsp;
        <a href="#Parceiros">Parceiros</a>&nbsp;&nbsp;&nbsp;
        <a href="#Contato">Contato</a>&nbsp;&nbsp;&nbsp;
        <a href={linkfbconfig} target="_blank"><FaFacebookSquare /></a>&nbsp;&nbsp;&nbsp;
        <a href={linkinstagramconfig} target="_blank"><FaInstagram /></a>&nbsp;&nbsp;&nbsp;

        <Menu className="menu-wrap">
        <a className="menu-item" href="#Início">Início</a>
        <a className="menu-item" href="#Trabalhos">Trabalhos Desenvolvidos</a>
        <a className="menu-item" href="#Projetos">Projetos</a>
        <a className="menu-item" href="#Noticias">Notícias</a>
        <a className="menu-item" href="#Downloads">Downloads</a>
        <a className="menu-item" href="#Cidades">Cidades</a>
        <a className="menu-item" href="#Receptivos">Receptivos</a>
        <a className="menu-item" href="#Parceiros">Parceiros</a>
        <a className="menu-item" href="#Contato">Contato</a>
        <a onClick={ showSettings } className="menu-item--small" href="">Settings</a>
        </Menu>
      </div>
  
      <div className="TextoOCircuito" id="Início">
        <div className="Textocircuito-divesquerda">
            O CIRCUITO TRILHA DOS INCONFIDENTES
            <br></br>
            {texto1}
            <br></br><br></br>
            {texto2}
        </div>
        <div className="Textocircuito-divdireita">
            <YouTube videoId={linkvideo} opts={opts} onReady={onReady} />
            <br></br>
            {texto3}
        </div>
      </div>
  
      <div className="Espaco">
      </div>
  
      <div className="Titulo" id="Trabalhos">
        <div className="FundoTitulo" id="Notícias">
        TRABALHOS DESENVOLVIDOS
        </div>
      </div>
  
      <div className="Trabalhos">
          <tbody>
          {trabalhos.map((item3, index)=>{
            return(
              <div className="TrabalhoInterno">
              <div style={{color: '#EE3135', marginTop: 20, textAlign: "justify",}}>
                {item3.nometrabalho}
                </div>
                <i>{item3.textosimplestrabalho}</i><br></br><br></br>
                <div className="BotãoCinza"><a onClick={ () => togglePostModalTrabalho(item3) }>SAIBA MAIS</a></div>
              </div>
            )
          })}
          </tbody>
      </div>
  
      <div className="Titulo" id="Projetos">
        <div className="FundoTitulo" id="Notícias">
        PROJETOS
        </div>
      </div>
      <div className="Projetos">
      {projetos.map((item4, index)=>{
                  return(
        <div className="ProjetoInterno">
          <div>
          { item4.avatarUrlprojeto == null ? 
                <img src={avatar} className="Projetoimg" alt={item4.nomeprojeto} />
                :
                <img src={item4.avatarUrlprojeto} className="Projetoimg" alt={item4.nomeprojeto} />
              }<br></br>
              {item4.nomeprojeto}<br></br>
              <div>
              <a href={item4.linkfbprojeto} target="_blank"><FaFacebookSquare /></a>&nbsp;&nbsp;
              <a href={item4.linkinstagramprojeto} target="_blank"><FaInstagram /></a>&nbsp;&nbsp;
              <a href={item4.linksiteprojeto} target="_blank"><FaLaptop /></a>&nbsp;&nbsp;
              </div>
              <br></br>
          </div>
          <div className="BotãoCinza"><a onClick={ () => togglePostModalProjeto(item4) }>SAIBA MAIS</a></div>
        </div>
                          )
                        })}
      </div>
  
      <div className="Espaco">
      </div>
      <div className="Titulo" id="Notícias">
        <div className="FundoTitulo" id="Notícias">
        NOTÍCIAS
        </div>
      </div>
      <div className="Noticias">
      {imprensa.map((item2, index)=>{
        return(
          <a onClick={ () => togglePostModalImprensa(item2) }>
          <div className="Imprensacada">
              { item2.avatarUrlimprensa == null ? 
                <img src={avatar} width="300" height="220" object-fit="scale-down" alt={item2.tituloimprensa} />
                :
                <img src={item2.avatarUrlimprensa} className="Imprensaimg" alt={item2.tituloimprensa} />
              }
              <br></br>
              {item2.tituloimprensa}
          </div>
          </a>
        )
      })}
      </div>

      <div className="Titulo" id="Cidades">
        <div className="FundoTitulo" id="Notícias">
        CIDADES
        </div>
      </div>
      <div className="Cidades">
          {cidades.map((item, index)=>{
                  return(
                    <a onClick={ () => togglePostModal(item) }>
                    <div className="Cidadescada" style={{
                        //backgroundImage: url"('"+{item.avatarUrlcidade}+"')",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                      }} >
              { item.avatarUrlcidade == null ? 
                <img src={avatar} className="Cidadeimg" alt={item.nomecidade} />
                :
                <img src={item.avatarUrlcidade} className="Cidadeimg" alt={item.nomecidade} />
              }
              <br></br>
              {item.nomecidade}
                    </div>
                    </a>
                  )
                })}
      </div>
      <div className="Titulo" id="Receptivos">
       <div className="FundoTitulo" id="Notícias">
      RECEPTIVOS
      </div>
      </div>
      <div className="Receptivos" id="Receptivos">
em breve
      </div>
  
      <div className="Espaco">
      </div>

      <div className="Titulo" id="Parceiros">
      <div className="FundoTitulo" id="Notícias">
      PARCEIROS
      </div>
      </div>
  
      <div className="Parceiros">
        <div style={{height: "100%", width: "100%", display: "flex", alignContent: "center", alignSelf: "center",   textalign: "center"}}>
        {parceiros.map((item, index)=>{
                  return(
        <div className="Parceiroscada">
          <a href={item.linksiteparceiro} target="_blank">
          { item.avatarUrlparceiro == null ? 
                <img src={avatar} width="auto" height="50" object-fit="scale-down" alt={item.nomeparceiro} />
                :
                <img src={item.avatarUrlparceiro} width="auto" height="50" object-fit="scale-down" alt={item.nomeparceiro} />
              }
          </a>
        </div>
            )
          })}
        </div>
      </div>
  
      <div className="Titulo" id="Notícias">
        <div className="FundoTitulo" id="Notícias">
        DOWNLOADS
        </div>
      </div>
      <div className="Downloads">
        <div className="Drive">
        <iframe src={linkdriveconfig} width="100%" height="400px" border="0"></iframe>
        </div>
      </div>
  
      <div className="Rodape" id="Contato">
        <div style={{width: "20%",textAlign: "center", verticalAlign: "center",}}>
          <img src={logotrilha} className="App-trilha2"  alt="Trilha dos Inconfidentes" />
        </div>
        <div style={{width: "30%", textAlign: "left", margin: 5,}}>
          Redes Sociais:<br></br>
          <a href={linkfbconfig} target="_blank"><FaFacebookSquare color="#000" size={25}/></a>&nbsp;&nbsp;
          <a href={linkinstagramconfig} target="_blank"><FaInstagram color="#000" size={25}/></a>&nbsp;&nbsp;<br></br><br></br>
          Whatsapp e Telefones:<br></br>
          <a href="https://api.whatsapp.com/send?phone=5532999738011" target="_blank"><FaWhatsappSquare />&nbsp;&nbsp;Administrativo</a>&nbsp;&nbsp;{telefone1config}<br></br>
          <a href="https://api.whatsapp.com/send?phone=5521996371207" target="_blank"><FaWhatsappSquare />&nbsp;&nbsp;Comunicação</a>&nbsp;&nbsp;{telefone2config}<br></br><br></br>
          E-mail:<br></br>
          <a mailto={emailconfig}><FaMailBulk />&nbsp;&nbsp;{emailconfig}</a><br></br>
        </div>
        <div style={{width: "50%", textAlign: "left", verticalAlign: "center",}}>
        Endereço:<br></br> 
          {logradouroconfig}, {numeroconfig}, {complementoconfig}, 
          {bairroconfig}, {cepconfig}<br></br>
          {cidadeconfig},<br></br>
          {estadoconfig}, {paisconfig}
        </div>
  
      <div style={{width: 150, height: 150, textAlign: "left", margin: 5,}}>
      <Link to="/termousuario">Política de Privacidade - LGPD</Link>
      </div>
 
      </div>
      <div className="Tonatrilha">
      Todos os direitos reservados Tô na Trilha© {razaoconfig}, {cnpjconfig} <Link to="/login"><FiSettings color="#000" size={16} /></Link>    
      </div>

      <div id="posiciona"> 
            <img src={logotrilharetangular} className="App-trilha"  alt="Trilha dos Inconfidentes" />
            <br></br>
            <img src={logominasbranco} className="App-trilha2"  alt="Minas" />
            <br></br>
            <img src={logotonatrilha} className="App-trilha2"  alt="Aplicativo Tô na Trilha" />
            <br></br>
            Baixem nosso aplicativo e fique por dentro do Circuito Trilha dos Inconfidentes:
            <br></br> 
            <a href={linkapplestoreconfig} target="_blank"><img src={apple} className="App-baixar"  alt="Trilha dos Inconfidentes" /></a>
            <a href={linkgoogleplayconfig} target="_blank"><img src={google} className="App-baixar"  alt="Trilha dos Inconfidentes" /></a>
      </div>

      {showPostModal && (
        <Modalcidade
          conteudo={detail}
          close={togglePostModal}
        />
      )}
      {showPostModal2 && (
        <Modalimprensa
          conteudo={detail2}
          close={togglePostModalImprensa}
        />
      )}
      {showPostModal3 && (
        <Modaltrabalho
          conteudo3={detail2}
          close={togglePostModalTrabalho}
        />
      )}
      {showPostModal4 && (
        <Modalprojeto
          conteudo4={detail2}
          close={togglePostModalProjeto}
        />
      )}
  </div>
  )
}