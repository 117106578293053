
import './dashboard.css';
import { useState, useEffect } from 'react';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiMessageSquare, FiPlus, FiSearch, FiEdit2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import firebase from '../../services/firebaseConnection';
import Modal from '../../components/Modal';

const listRef = firebase.firestore().collection('textos').orderBy('created', 'desc');

export default function Dashboard(){
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();

  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();

    return(
      <div>
        <Header/>

        <div className="content">
          <Title name="Tô na Trilha">
            <FiMessageSquare size={25} />
          </Title>  

          <div className="container dashboard">
            <span>Seja bem vindo. Estamos em desenvolvimento.</span>
          </div>

        </div>      
      </div>
  
  )
}