
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import firebase from '../../services/firebaseConnection';

import { AuthContext } from '../../contexts/auth';
import './signin.css';
import logo from '../../assets/logo.png';
import logotrilha from '../../assets/logotrilhasup.png';



function SignTermo() {
  const [complemento, setComplemento] = useState();
const [texto1, setTexto1] = useState();
const [texto2, setTexto2] = useState();
const [texto3, setTexto3] = useState();
const [texto4, setTexto4] = useState();
const [texto5, setTexto5] = useState();
const [texto6, setTexto6] = useState();
const [linkvideo, setLinkvideo] = useState();

const [nomeconfig, setNomeconfig] = useState('');
const [sloganconfig, setSloganconfig]= useState('');
const [desccurtaconfig, setDesccurtaconfig] = useState('');
const [palavrachaveconfig, setPalavrachaveconfig] = useState('');
const [razaoconfig, setRazaoconfig] = useState('');
const [cnpjconfig, setCnpjconfig] = useState('');
const [cepconfig, setCepconfig] = useState('');
const [logradouroconfig, setLogradouroconfig] = useState('');
const [numeroconfig, setNumeroconfig] = useState('');
const [complementoconfig, setComplementoconfig] = useState('');
const [bairroconfig, setBairroconfig] = useState('');
const [cidadeconfig, setCidadeconfig] = useState('');
const [estadoconfig, setEstadoconfig] = useState('');
const [paisconfig, setPaisconfig] = useState('');
const [siteconfig, setSiteconfig] = useState('');
const [emailconfig, setEmailconfig] = useState('');
const [linkinstagramconfig, setLinkinstagramconfig] = useState('');
const [linkfbconfig, setLinkfbconfig] = useState('');
const [linkyoutubeconfig, setLinkyoutubeconfig] = useState('');
const [linkdriveconfig, setLinkdriveconfig] = useState('');
const [linklinkedconfig, setLinklinkedconfig] = useState('');
const [linkapplestoreconfig, setLinkapplestoreconfig] = useState('');
const [linkgoogleplayconfig, setLinkgoogleplayconfig] = useState('');
const [telefone1config, setTelefone1config] = useState('');
const [telefone2config, setTelefone2config] = useState('');
const [whats1config, setWhats1config] = useState('');
const [whats2config, setWhats2config] = useState('');

useEffect(()=> {

  async function loadTextos(){
    await firebase.firestore().collection('textos')
    .doc('Dj1URS8XolLShHWwG6cP')
    .get()
    .then((snapshot) => {
      setComplemento(snapshot.data().complemento);  
      setTexto1(snapshot.data().texto1);
      setTexto2(snapshot.data().texto2);
      setTexto3(snapshot.data().texto3);
      setTexto4(snapshot.data().texto4);
      setTexto5(snapshot.data().texto5);
      setTexto6(snapshot.data().texto6);
      setLinkvideo(snapshot.data().linkvideo)
    })

  }
  loadTextos();

  async function loadConfig(){
    await firebase.firestore().collection('config')
    .doc('3KG4oi52D34HlaTEiJOL')
    .get()
    .then((snapshot) => {
      setNomeconfig(snapshot.data().nomeconfig);
      setSloganconfig(snapshot.data().sloganconfig);
      setDesccurtaconfig(snapshot.data().desccurtaconfig);
      setPalavrachaveconfig(snapshot.data().palavrachaveconfig);
      setRazaoconfig(snapshot.data().razaoconfig);
      setCnpjconfig(snapshot.data().cnpjconfig);
      setCepconfig(snapshot.data().cepconfig);
      setLogradouroconfig(snapshot.data().logradouroconfig);
      setNumeroconfig(snapshot.data().numeroconfig);
      setComplementoconfig(snapshot.data().complementoconfig);
      setBairroconfig(snapshot.data().bairroconfig);
      setCidadeconfig(snapshot.data().cidadeconfig);
      setEstadoconfig(snapshot.data().estadoconfig);
      setPaisconfig(snapshot.data().paisconfig);
      setSiteconfig(snapshot.data().siteconfig);
      setEmailconfig(snapshot.data().emailconfig);
      setLinkinstagramconfig(snapshot.data().linkinstagramconfig);
      setLinkfbconfig(snapshot.data().linkfbconfig);
      setLinkyoutubeconfig(snapshot.data().linkyoutubeconfig);
      setLinkdriveconfig(snapshot.data().linkdriveconfig);
      setLinklinkedconfig(snapshot.data().linklinkedconfig);
      setLinkapplestoreconfig(snapshot.data().linkapplestoreconfig);
      setLinkgoogleplayconfig(snapshot.data().linkgoogleplayconfig);
      setTelefone1config(snapshot.data().telefone1config);
      setTelefone2config(snapshot.data().telefone2config);
      setWhats1config(snapshot.data().whats1config);
      setWhats2config(snapshot.data().whats2config)
    })
  }
  loadConfig();


  return () => {

  }
}, []);

  return (
    <div className="container-center">
      <div className="login">
        <div className="login-area">
          <img src={logotrilha} alt="Sistema Logo" />
        </div>

        <form>
          <h1>Política de Privacidade - LGPD</h1>
          {texto6}
        </form>  


        <div className="login-area">
        <Link to="/">Voltar</Link>
        </div>
      </div>
    </div>
  );
  }

export default SignTermo;
