
import './modal.css';

import { FaFacebookSquare, FaInstagram, FaWhatsappSquare, FaLaptop } from "react-icons/fa";
import { FiX } from 'react-icons/fi';


export default function Modalimprensa({conteudo, close}){
  return(
    <div className="modal">
      <div className="container">
        <button className="close" onClick={ close }>
          <FiX size={23} color="#FFF" />
          Fechar
        </button>

        <div>
          <h2>{conteudo.tituloimprensa}</h2>
          <div className="row">
            <span>
              {conteudo.texto1imprensa}
            </span>
          </div>
          </div>
      </div>
    </div>
  )
}