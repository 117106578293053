
import { useState, useEffect, useContext } from 'react';

import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import avatar from '../imagens/img.png';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './textos.css';
import { FiPlusCircle, FiUpload} from 'react-icons/fi'

export default function Textos(){
  const { id } = useParams();
  const history = useHistory();

  const [loadSecoes, setLoadSecoes] = useState(true);
  const [secoes, setSecoes] = useState([]);
  const [secoesSelected, setSecoesSelected] = useState(0);

  const [assuntoimprensa, setAssuntoimprensa] = useState('Notícias');
  const [statusimprensa, setStatusimprensa] = useState('');
  const [tituloimprensa, setTituloimprensa] = useState('');
  const [texto1imprensa, setTexto1imprensa] = useState('');
  const [texto2imprensa, setTexto2imprensa] = useState('');
  const [texto3imprensa, setTexto3imprensa] = useState('');
  const [texto4imprensa, setTexto4imprensa] = useState('');
  const [texto5imprensa, setTexto5imprensa] = useState('');
  const [texto6imprensa, setTexto6imprensa] = useState('');


  const [idSecao, setIdSecao] = useState(false);

  const [avatarUrlimprensa, setAvatarUrlimprensa] = useState(null);
  const [avatarUrlimprensar, setAvatarUrlimprensar] = useState(null);
  const [imageAvatar, setImageAvatar] = useState(null);

  const [uploading, setUploading] = useState(false);

  const { user } = useContext(AuthContext);


  useEffect(()=> {
    async function loadSecoes(){
      await firebase.firestore().collection('secao')
      .get()
      .then((snapshot)=>{
        let lista = [];

        snapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            nomesecao: doc.data().nomesecao
          })
        })

        if(lista.length === 0){
          console.log('NENHUMA SEÇÃO ENCONTRADA');
          setSecoes([ { id: '1', nomesecao: 'AVULSO' } ]);
          setLoadSecoes(false);
          return;
        }

        setSecoes(lista);
        setLoadSecoes(false);

        if(id){
          loadId(lista);
        }

      })
      .catch((error)=>{
        console.log('DEU ALGUM ERRO!', error);
        setLoadSecoes(false);
        setSecoes([ { id: '1', nomesecao: '' } ]);
      })

    }

    loadSecoes();

  }, [id]);



  async function loadId(lista){
    await firebase.firestore().collection('imprensa').doc(id)
    .get()
    .then((snapshot) => {
      setAssuntoimprensa(snapshot.data().assuntoimprensa);
      setTituloimprensa(snapshot.data().tituloimprensa);
      setAvatarUrlimprensa(snapshot.data().avatarUrlimprensa);
      setStatusimprensa(snapshot.data().statusimprensa);
      setTituloimprensa(snapshot.data().tituloimprensa);
      setTexto1imprensa(snapshot.data().texto1imprensa);
      setTexto2imprensa(snapshot.data().texto2imprensa);
      setTexto3imprensa(snapshot.data().texto3imprensa);
      setTexto4imprensa(snapshot.data().texto4imprensa);
      setTexto5imprensa(snapshot.data().texto5imprensa);
      setTexto6imprensa(snapshot.data().texto6imprensa)

      let index = lista.findIndex(item => item.id === snapshot.data().secaoId );
      setSecoesSelected(index);
      setIdSecao(true);

    })
    .catch((err)=>{
      console.log('ERRO NO ID PASSADO: ', err);
      setIdSecao(false);
    })
  }

  function handleFile(e){
    if(e.target.files[0]){
      const image = e.target.files[0];
      
      if(image.type === 'image/jpeg' || image.type === 'image/png'){

        setImageAvatar(image);
        setAvatarUrlimprensa(URL.createObjectURL(e.target.files[0]))

      }else{
        alert('Envie uma imagem do tipo PNG ou JPEG');
        setImageAvatar(null);
        return null;
      }
    }
  }

  async function handleUpload(){
    toast.success('Envio Iniciado!');
    if (imageAvatar){
    const uploadTask = await firebase.storage()
    .ref(`images/imprensa/${id}/avatar/Avatar`)
    .put(imageAvatar)
    .then( async () => {
      toast.success('Envio Completo!');
      await firebase.storage().ref(`images/imprensa/${id}/avatar/`)
      .child("Avatar").getDownloadURL()
      .then( async (url)=>{
        let urlFoto = url;
        toast.success(`enviou corrreto ${urlFoto}`);
        
        await firebase.firestore().collection('imprensa')
        .doc(id)
        .update({
          avatarUrlimprensa: urlFoto,
          secao: secoes[secoesSelected].nomesecao,
          secaoId: secoes[secoesSelected].id,
          assuntoimprensa: assuntoimprensa,
          statusimprensa: statusimprensa,
          tituloimprensa: tituloimprensa,
          texto1imprensa: texto1imprensa,
          texto2imprensa: texto2imprensa,
          texto3imprensa: texto3imprensa,
          texto4imprensa: texto4imprensa,
          texto5imprensa: texto5imprensa,
          texto6imprensa: texto6imprensa,
          userId: user.uid
        })
        .then(()=>{
          toast.success(`Foto Enviada e Texto editada com sucesso! ${id}`);
          setSecoesSelected(0);
          setTituloimprensa('');
          history.push('/imprensalistar');
        })
      })
    })
      }
      else {
      alert('Upload failed, sorry :( 39');
    } 
  };




  async function handleRegister(e){
    e.preventDefault();

    if(idSecao === true && imageAvatar === null){
      await firebase.firestore().collection('imprensa')
      .doc(id)
      .update({
        secao: secoes[secoesSelected].nomesecao,
        secaoId: secoes[secoesSelected].id,
        assuntoimprensa: assuntoimprensa,
        statusimprensa: statusimprensa,
        tituloimprensa: tituloimprensa,
        texto1imprensa: texto1imprensa,
        texto2imprensa: texto2imprensa,
        texto3imprensa: texto3imprensa,
        texto4imprensa: texto4imprensa,
        texto5imprensa: texto5imprensa,
        texto6imprensa: texto6imprensa,
        userId: user.uid
      })
      .then(()=>{
        toast.success('Texto Editado com sucesso!');
        setSecoesSelected(0);
        setTituloimprensa('');
        setTexto1imprensa('');
        setTexto2imprensa('');
        setTexto3imprensa('');
        setTexto4imprensa('');
        setTexto5imprensa('');
        setTexto6imprensa('');
        history.push('/imprensalistar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

    }
    else if (idSecao === true && imageAvatar !== null){
      handleUpload();
    }

    else if(idSecao === false){

    await firebase.firestore().collection('imprensa')
    .add({
    created: new Date(),
    secao: secoes[secoesSelected].nomesecao,
    secaoId: secoes[secoesSelected].id,
    assuntoimprensa: assuntoimprensa,
    statusimprensa: statusimprensa,
    tituloimprensa: tituloimprensa,
    texto1imprensa: texto1imprensa,
    texto2imprensa: texto2imprensa,
    texto3imprensa: texto3imprensa,
    texto4imprensa: texto4imprensa,
    texto5imprensa: texto5imprensa,
    texto6imprensa: texto6imprensa,
    userId: user.uid
    })
    .then(()=> {
      toast.success('Texto criado com sucesso!');
      setSecoesSelected(0);
      setTituloimprensa('');
      setTexto1imprensa('');
      setTexto2imprensa('');
      setTexto3imprensa('');
      setTexto4imprensa('');
      setTexto5imprensa('');
      setTexto6imprensa('');
      history.push('/imprensalistar');
    })
    .catch((err)=> {
      toast.error('Ops erro ao registrar, tente mais tarde.')
      console.log(err);
    })
  }

  }




  //Chamado quando troca o assunto
  function handleChangeSelect(e){
    setAssuntoimprensa(e.target.value);
  }


  //Chamado quando troca o status
  function handleOptionChange(e){
    setStatusimprensa(e.target.value);
  }

  //Chamado quando troca de cliente
  function handleChangeSecoes(e){
    //console.log('INDEX DO CLIENTE SELECIONADO: ', e.target.value);
    //console.log('Cliente selecionado ', customers[e.target.value])
    setSecoesSelected(e.target.value);
  }

  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Imprensa">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">

          <form className="form-profile"  onSubmit={handleRegister} >
            
            <label>Seções</label>

            {loadSecoes ? (
              <input type="text" disabled={true} value="Carregando seções..." />
            ) : (
                <select value={secoesSelected} onChange={handleChangeSecoes} >
                {secoes.map((item, index) => {
                  return(
                    <option key={item.id} value={index} >
                      {item.nomesecao}
                    </option>
                  )
                })}
              </select>
            )}

            <label>Assunto</label>
            <select value={assuntoimprensa} onChange={handleChangeSelect}>
              <option value="Blog">Blog</option>
              <option value="Notícias">Notícias</option>
              <option value="Roteiros">Roteiros</option>
            </select>

            <label>Status</label>
            <div className="statusimprensa">
              <input 
              type="radio"
              name="radio"
              value="Web"
              onChange={handleOptionChange}
              checked={ statusimprensa === 'Web' }
              />
              <span>Web</span>

              <input 
              type="radio"
              name="radio"
              value="App"
              onChange={handleOptionChange}
              checked={ statusimprensa === 'App' }
              />
              <span>App</span>

              <input 
              type="radio"
              name="radio"
              value="Ambos"
              onChange={handleOptionChange}
              checked={ statusimprensa === 'Ambos' }
              />
              <span>Ambos</span>
            </div>

            <label>Titulo</label>
            <input type="text" placeholder="Título" value={tituloimprensa} onChange={ (e) => setTituloimprensa(e.target.value) } />
            <label>
              <i>Imagens: prefira imagens entre 1000 x 1000 pixel</i>
            </label>
            { idSecao === true ?
            <label className="label-avatar">
              <span>
                <FiUpload color="#FFF" size={25} />
              </span>
            <input type="file" accept="image/*" onChange={handleFile}  /><br/>
              { avatarUrlimprensa == null ? 
                <img src={avatar} width="250" height="250" alt="Sem Foto Cidade" />
                :
                <img src={avatarUrlimprensa} width="250" height="250" alt="Foto Cidade" />
              }
            </label>
            :
            <label className="label-avatar">Complete o registro de texto liberar a colocação de foto.
              <span>
                <FiUpload color="#000" size={25} />
              </span>
            </label>
          }

            <label>Texto Principal</label>
            <textarea
              type="text"
              placeholder="Coloque aqui seu texto"
              value={texto1imprensa}
              style={{height: 400 }}
              onChange={ (e) => setTexto1imprensa(e.target.value) }
            />
            <label>Texto 2</label>
            <textarea
              type="text"
              placeholder="Coloque aqui seu texto"
              value={texto2imprensa}
              onChange={ (e) => setTexto2imprensa(e.target.value) }
            />
            <label>Texto 3</label>
            <textarea
              type="text"
              placeholder="Coloque aqui seu texto"
              value={texto3imprensa}
              onChange={ (e) => setTexto3imprensa(e.target.value) }
            />
            <label>Texto 4</label>
            <textarea
              type="text"
              placeholder="Coloque aqui seu texto"
              value={texto4imprensa}
              onChange={ (e) => setTexto4imprensa(e.target.value) }
            />
            <label>Texto 5</label>
            <textarea
              type="text"
              placeholder="Coloque aqui seu texto"
              value={texto5imprensa}
              onChange={ (e) => setTexto5imprensa(e.target.value) }
            />
            <label>Texto 6</label>
            <textarea
              type="text"
              placeholder="Coloque aqui seu texto"
              value={texto6imprensa}
              onChange={ (e) => setTexto6imprensa(e.target.value) }
            />
            <button type="submit">Registrar</button>

          </form>

        </div>

      </div>
    </div>
  )
}