import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

let firebaseConfig = {
  apiKey: "AIzaSyDJJFKvgHH1VYIwXvqKk0dNg96Wt5gqLLI",
  authDomain: "to-na-trilha.firebaseapp.com",
  projectId: "to-na-trilha",
  storageBucket: "to-na-trilha.appspot.com",
  messagingSenderId: "490453670610",
  appId: "1:490453670610:web:504ba27f318305fa282b0b",
  measurementId: "G-K2H0KV5EBH"
};

if(!firebase.apps.length){
  firebase.initializeApp(firebaseConfig);
}

export default firebase;