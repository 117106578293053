
import { useState, useEffect, useContext } from 'react';
import firebase from '../../services/firebaseConnection';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Title from '../../components/Title';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';

import './projetos.css';
import { FiPlusCircle } from 'react-icons/fi'

export default function Fotos(){
  const { id } = useParams();
  const history = useHistory();

  const [source, setSource] = useState('');
  const [uid, setUid] = useState('');
  const [verificado, setVerificado] = useState('false');
  const [momentof, setMomentof] = useState('');

  const [idfotos, setIdfotos] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(()=> {

    async function loadFotos(){
      await firebase.firestore().collection('fotos')
      .doc(id)
      .get()
      .then((snapshot) => {
        setSource(snapshot.data().source);
        setUid(snapshot.data().uid);
        setVerificado(snapshot.data().verificado);
        setMomentof(snapshot.data().momentof);
        setIdfotos(id);
      })

    }

    loadFotos();
    return () => {

    }
  }, []);


  //Chamado quando troca o assunto
  function handleChangeVerificado(e){
    setVerificado(e.target.value);
  }


  async function handleRegister(e){
    e.preventDefault();

    if(idfotos){
      await firebase.firestore().collection('fotos')
      .doc(id)
      .update({
        source: source,
        uid: uid,
        verificado: verificado,
        momentof: momentof
      })
      .then(()=>{
        toast.success('Foto atualizada com sucesso!');
        history.push('/fotoslistaravaliar');
      })
      .catch((err)=>{
        toast.error('Ops erro ao registrar, tente mais tarde.')
        console.log(err);
      })

      return;
    }
  }


  return(
    <div>
      <Header/>

      <div className="content">
        <Title name="Fotos">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">
        <img src={source} width="400" height="400"   margin="5"/>
          <form className="form-profile"  onSubmit={handleRegister} >
            <label>Verifcar foto:</label>
            <select value={verificado} onChange={handleChangeVerificado}>
              <option value="false">Não Verificado</option>
              <option value="true">Sim Verificado</option>
              <option value="naoexibir">Não Exibir</option>
            </select>

            <label>Foto</label>
            <input type="text" value={uid} disabled={true} />  
            <label>Dados</label>
            <input type="text" value={id} disabled={true} />  
            <input type="text" value={source} disabled={true} />
            <input type="text" value={momentof} disabled={true} />
            <button type="submit">Registrar</button>
          </form>
        </div>

      </div>
    </div>
  )
}